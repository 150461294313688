// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {hbfssvnoL: {hover: true}};

const cycleOrder = ["hbfssvnoL"];

const serializationHash = "framer-j26VA"

const variantClassNames = {hbfssvnoL: "framer-v-jt1feh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color, colorHover, height, iconChange, id, link, width, ...props}) => { return {...props, mlyFVl4P9: iconChange ?? props.mlyFVl4P9 ?? "twitter", UIN8789uh: link ?? props.UIN8789uh, uRWOA3vUm: colorHover ?? props.uRWOA3vUm ?? "var(--token-09f22285-1280-482e-8b22-1cef593bf6a7, rgb(255, 255, 255))", YFVOV5lUK: color ?? props.YFVOV5lUK ?? "var(--token-2c541943-a99a-4ecb-bbe1-39c81e32ad57, rgb(142, 142, 142))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconChange?: string;color?: string;colorHover?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mlyFVl4P9, YFVOV5lUK, uRWOA3vUm, UIN8789uh, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hbfssvnoL", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={UIN8789uh} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-jt1feh", className, classNames)} framer-1moy7fh`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"hbfssvnoL"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"hbfssvnoL-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1cuw5sb-container"} layoutDependency={layoutDependency} layoutId={"oxZA1cbZH-container"}><Phosphor color={YFVOV5lUK} height={"100%"} iconSearch={mlyFVl4P9} iconSelection={"House"} id={"oxZA1cbZH"} layoutId={"oxZA1cbZH"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"duotone"} width={"100%"} {...addPropertyOverrides({"hbfssvnoL-hover": {color: uRWOA3vUm}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-j26VA.framer-1moy7fh, .framer-j26VA .framer-1moy7fh { display: block; }", ".framer-j26VA.framer-jt1feh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-j26VA .framer-1cuw5sb-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-j26VA.framer-jt1feh { gap: 0px; } .framer-j26VA.framer-jt1feh > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-j26VA.framer-jt1feh > :first-child { margin-left: 0px; } .framer-j26VA.framer-jt1feh > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"o9iq4jvkD":{"layout":["auto","auto"]}}}
 * @framerVariables {"mlyFVl4P9":"iconChange","YFVOV5lUK":"color","uRWOA3vUm":"colorHover","UIN8789uh":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLrcQZUNfN: React.ComponentType<Props> = withCSS(Component, css, "framer-j26VA") as typeof Component;
export default FramerLrcQZUNfN;

FramerLrcQZUNfN.displayName = "Elements/Social icon button";

FramerLrcQZUNfN.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerLrcQZUNfN, {mlyFVl4P9: {defaultValue: "twitter", placeholder: "Menu, Wifi, Box…", title: "Icon change", type: ControlType.String}, YFVOV5lUK: {defaultValue: "var(--token-2c541943-a99a-4ecb-bbe1-39c81e32ad57, rgb(142, 142, 142)) /* {\"name\":\"Gray-4\"} */", title: "Color", type: ControlType.Color}, uRWOA3vUm: {defaultValue: "var(--token-09f22285-1280-482e-8b22-1cef593bf6a7, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", title: "Color hover", type: ControlType.Color}, UIN8789uh: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerLrcQZUNfN, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})